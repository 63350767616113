/**
 * The file contents for the current environment will overwrite these during
 * build. The build system defaults to the dev environment which uses
 * `environment.ts`, but if you do `ng build --env=prod` then
 * `environment.prod.ts` will be used instead. The list of which env maps to
 * which file can be found in `angular-cli.json`. `.env.ts` is generated by the
 * `npm run env` command import env from './.env';
 */

/**
 * @ignore
 */
 let production = 'true';
 let serverUrl = 'https://ruckit-platform.herokuapp.com/api/lafarge/';
 let authUrl = 'https://ruckit-platform.herokuapp.com/api/';
 
 export const environment = {
   production: production === 'true',
   serverUrl: serverUrl,
   authUrl: authUrl,
   defaultLanguage: 'en-US',
   supportedLanguages: [
     'en-US',
     'fr-FR'
   ]
 };
 